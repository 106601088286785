import React, { useEffect } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';
import './contact.css';

import { SiMinutemailer } from "react-icons/si";
import {FaFacebookMessenger} from 'react-icons/fa'
import {RiWhatsappFill} from 'react-icons/ri'

import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  const form = useRef ();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_xs6ju6a', 'template_4j3mrd7', form.current, 'Kt85fQA57vN0cdgGR')
      
    e.target.reset()
  };

  return (
    <>
    
    <div data-aos='fade-up' className='container-width contact__back-image'>
      <h2 data-aos='fade-right'>Contatos</h2>
    </div>
    <section id='contact'>
      <div className='container-width contact'>     
      <div className='container contact__container'>
      <div className='contact__options'>

        <article data-aos='fade-up' className='contact__option'>
        <SiMinutemailer className='contact__option-icon' />
          <div>
            <h4>Email</h4>
            <p>geral@ndozycomunicacoes.com</p>
            <a href='mailto:geral@ndozycomunicacoes.com' target='blank'>Enviar Mensagem</a>
          </div>
        </article>
        <article data-aos='fade-up' className='contact__option'>
        <FaFacebookMessenger className='contact__option-icon'/>
          <div>  
            <h4>Messenger</h4> 
            <p>Ndozy Comunicaçoes</p>
            <a href='https://m.me/ndozycomunicacoes' target='blank'>Enviar Mensagem</a>
          </div>
        </article>
        <article data-aos='fade-up' className='contact__option'>
        <RiWhatsappFill className='contact__option-icon'/>
          <div>  
            <h4>Whatsapp</h4>
            <p>+244 923 429 931</p>
            <p>+244 993 429 931</p>
            <a href='https://api.whatsapp.com/send?phone+923429931' target='blank'>Enviar Mensagem</a>
          </div>
        </article>
      </div>

        <form data-aos='fade-up' ref={form} onSubmit={sendEmail}>
          <h3>DUVIDAS<br/>E SUGESTÕES</h3>
          <p data-aos='fade-right' data-aos-duration='1000'>Insira seus dados nos espaços abaixo</p>
          <input data-aos='fade-right' data-aos-duration='2000' type='text' name='name' placeholder='Seu nome completo' required/>
          <input data-aos='fade-right' data-aos-duration='4000' type='email' name='email' placeholder='Seu email' required/>
          <textarea data-aos='fade-right' data-aos-duration='6000' name='message' rows={7} placeholder='Digitar mensagem' required></textarea>
          <button data-aos='fade-right' data-aos-duration='8000' type='submit' className='btn btn-secundary'>Enviar Mensagem</button>
        </form>

      </div>
      </div>
    </section>
    </>
  )
}

export default Contact;