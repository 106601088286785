import React from 'react'
import './footer.css'

import { Link } from 'react-router-dom';


import LOGONDOZY from '../../assets/logos/logo1.png';

import {BsFacebook} from 'react-icons/bs';
import {RiInstagramFill} from 'react-icons/ri';
import {BsYoutube} from 'react-icons/bs';

const Footer = () => {
  return (
    <footer>
        <div className='container-width-sm f-container'>
          <div>
            
          </div>
            <div className='f-left'>
                <Link to='/'><img src={LOGONDOZY} alt='logo-ndozy'/></Link>
                <p className='p-multimedia'>Trata-se de um grupo de comunicação e marketing, especializada em design e multimédia.</p>
                <div className='h-socials'>
                      <a href='https://www.facebook.com/ndozycomunicacoes' target='blank'><BsFacebook/></a>
                      <a href='https://www.instagram.com/ndozycomunicacoes/#' target='blank'><RiInstagramFill/></a>
                      <a href='https://www.youtube.com/@ndozycomunicacoes2944' target='blank'><BsYoutube/></a>
                </div>
            </div>
            <div>

            <div className='f-right'>
              <div className='f-details'>
                  <h4>MENU</h4>
                <div className='f-opc'>
                <Link to='/About'>Sobre</Link>
                <Link to='/Services'>Serviços</Link>
                <Link to='/Contact'>Contactos</Link>
                </div>
              </div>
              <div className='f-details'>
                  <h4>SERVIÇOS</h4>
                <div className='fff f-opc'>
                  <li href='#'>Branding</li>
                  <li href='#'>Campanhas</li>
                  <li href='#'>Web Sites</li>
                  <li href='#'>Redes Sociais</li>
                </div>
              </div>
              <div className='f-details'>
                  <h4>NEWSLETTER</h4>
                <div className='f-opc'>
                  <p>Conecta-se a Ndozy, estara informado sobre serviços e muito mais.</p>
                </div>
              </div>
            </div>
              <div className='footer__copyright'>
                <small>&copy; Ndozy Comunicações 2023</small>
              </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer;