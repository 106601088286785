import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Aos from 'aos';
import './index.css';

import 'aos/dist/aos.css';

import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Home from './paginas/Home.jsx';
import About from './paginas/About';
import Store from './paginas/Store';
import Services from './paginas/Services';
import Contact from './paginas/Contact';
import Recrutamento from './paginas/Recrutamento.jsx';



function App () {

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (  
    <>
    
    <BrowserRouter>
    
      <Navbar />
      
      <Routes>
      
        <Route exact path='/' element={<Home/>}/>
        <Route path='about' element={<About/>}/>
        <Route path='store' element={<Store/>}/>
        <Route path='services' element={<Services/>}/>
        <Route path='contact' element={<Contact/>}/>
        <Route path='recrutamento' element={<Recrutamento/>}/>
      
      </Routes>
      

      <Footer/>
   
   </BrowserRouter>
   
    </>
  )
}

export default App;