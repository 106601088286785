import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import SliderContent from './allslides/SliderContent';
import imageSlider from './allslides/imageSlider';
import Dots from './allslides/Dots';
import '../components/allslides/slider.css';

import SAIBAMAIS from '../assets/icons/icon-saiba-mais.png';

const len = imageSlider.length - 1;

function Slider (props) {

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
    
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
        }, 5000);
        return () => clearInterval (interval);
    }, [activeIndex]);

  return (
    <div data-aos='fade-up' className='slider-container'>
      
        <span className='slide-more'><Link to='/About'><span className='saiba-mais'><span className='icon-img'><img src={SAIBAMAIS} alt=''/></span>Saiba Mais</span></Link></span>

        <SliderContent activeIndex={activeIndex} imageSlider={imageSlider}/>

        <Dots activeIndex={activeIndex} imageSlider={imageSlider} onClick={activeIndex => setActiveIndex(activeIndex)}
        />
    </div>
  )
}

export default Slider;