import React, { useEffect } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';
import './about.css';

import IMAGE1 from '../assets/imagens/image1.png';
import IMAGE2 from '../assets/imagens/image2.png';
import IMAGE3 from '../assets/imagens/image3.png';

function About () {

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <>
    <div data-aos='fade-up' className='container-width about__back-image'>
      <h2 data-aos='fade-right'>Sobre Nós</h2>
    </div>
    <section id='About' className='about'>

      <div className='container about-container'>
        <div className='about-items'>
          <div className='about-images'>
            <img data-aos='fade-up' data-aos-duration='1000' className='image1' src={IMAGE1} alt='imagem-1'/>
            <div className='column-image'>
            <img data-aos='fade-up' data-aos-duration='2000'  className='image2' src={IMAGE2} alt='imagem-1'/>
            <img data-aos='fade-up' data-aos-duration='3000' className='image3' src={IMAGE3} alt='imagem-1'/>
            </div>
          </div>
          <div className='about-text'>

              <h4 data-aos='fade-up' data-aos-duration='2000'>QUEM SOMOS?</h4>
              <p data-aos='fade-up' data-aos-duration='4000'>Somos uma empresa de direito Angolano, com mais de 20 anos no mercado, actuando 
              no sector privado e público com clientes atuantes em diversos segmentos. Composta por 
              uma equipe de profissionais experientes, cujo o principal objectivo é materializar as 
              aspirações de nossos clientes.</p>

            <div className='about-text__details'>

              <div className='text__details'>
              <h4 data-aos='fade-up' data-aos-duration='2000'>Missão</h4>
              <p data-aos='fade-up' data-aos-duration='4000'>prestar serviço aos nossos clientes (empresa e organizações) com qualidade, eficiência, responsabilidade e integridade satisfazendo as suas mais altas expectativas cumprindo com os prazos assumidos e contribuir para o sucesso dos seus negócios tornando-os mais notáveis no mercado.</p>
              </div>

              <div className='text__details'>
              <h4 data-aos='fade-up' data-aos-duration='3000'>Visão</h4>
              <p data-aos='fade-up' data-aos-duration='4000'>Pensar global, agir local ( Be global, Act local), é nossa visão tornarmo-nos a longo prazo numa empresa sólida, dinâmica, inovadora, educadora e de entretenimento com reconhecimento nacional e internacional pela qualidade e profissionalismo na prestação dos serviços.</p>
              </div>
            </div>
            
          </div>
    
        </div>
        <div className='about-text__button'>
              <div className='text__details'>
                <h4 data-aos='fade-right' data-aos-duration='2000'>Valores</h4>
                <p data-aos='fade-up' data-aos-duration='4000' className='p'>Primamos por valores como Integridade, Responsabilidade, Comprometimento, Paixão e Qualidade nos serviços, em busca sempre da satisfação e sucesso dos clientes projeto após projecto.</p>
              </div>
              <div className='text__details'>
                <h4 data-aos='fade-right' data-aos-duration='2000'>Objectivos</h4>
                <p data-aos='fade-up' data-aos-duration='4000'>Ajudar as empresas a tornarem-se mais eficientes e eficazes na sua abordagem “comunicação” com o seu público alvo, tendo como finalidade a construção de boas relações com terceiros, educar o mercado e engajar colaboradores. Aumentar a visibilidade de marcas e produtos das empresas, com foco no aumento de vendas, fidelização de clientes e gerenciamento de marca.</p>
              </div>
            </div>
      </div>

    </section>
    </>
  )
}

export default About;