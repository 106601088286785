import React, { useEffect } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';
import './services.css'

import SPOTTV from '../assets/icons/tv.png';
import CAMPANHA from '../assets/icons/campanha.png';
import GRAFISMO from '../assets/icons/branding.png';
import SPOTRADIO from '../assets/icons/podcast.png';
import LAPTOP from '../assets/icons/laptop.png';
import CAPIMAGE from '../assets/icons/editimage.png';

const Services = () => {

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <>
    <div data-aos='fade-up' className='container-width sservices__back-image'>
      <h2 data-aos='fade-right'>Serviços</h2>
    </div>   
    <section  className='container-width services'>

      <div className='container services-container'>

        <div className='services-right'>

          <div data-aos='fade-up' data-aos-duration='1000' className='services-details'>
            <div className='lgg'>
              <div><img className='services-logos' src={CAMPANHA} alt='logo-redes-sociais'/></div>
              <div><h4>Criação de <br/> Campanhas</h4></div>
            </div>
          </div>

          <div data-aos='fade-up' data-aos-duration='1000' className='services-details'>
            <div className='lgg'>
              <div><img className='services-logos' src={SPOTTV} alt='logo-redes-sociais'/></div>
              <h4>Criação e Edição <br/> de Spot de Tv</h4>
            </div>
          </div>

          <div data-aos='fade-up' data-aos-duration='3000' className='services-details'>
            <div className='lgg'>
              <div><img className='services-logos' src={GRAFISMO} alt='logo-redes-sociais'/></div>
              <h4>Criação e Edição Grafismo/Info-Grafismo</h4>
            </div>
          </div>

          <div data-aos='fade-up' data-aos-duration='4000' className='services-details'>
            <div className='lgg'>
              <div><img className='services-logos' src={LAPTOP} alt='logo-redes-sociais'/></div>
              <h4>Edição de Video<br/> Institucional</h4>
            </div>
          </div>

          <div data-aos='fade-up' data-aos-duration='5000' className='services-details'>
            <div className='lgg'>
              <div><img className='services-logos' src={CAPIMAGE} alt='logo-redes-sociais'/></div>
              <h4>Captação de Imagem/<br/>Filmagens(Video e Fotografia)</h4>
            </div>
          </div>

          <div data-aos='fade-up' data-aos-duration='6000' className='services-details'>
            <div className='lgg'>
              <div><img className='services-logos' src={SPOTRADIO} alt='logo-redes-sociais'/></div>
              <h4>Criação e Edição <br/> Spot de Rarádio</h4>
            </div>
          </div>

        </div>
        
      </div>
    </section>
    </>
  )
}

export default Services;