import { useEffect } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';

function SliderContent ({ activeIndex, imageSlider })  {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <section data-aos='fade-up' >
        {imageSlider.map((slide, index) => (
            <div key={index} className={index === activeIndex ? "slides active" : "inactive"}>
              <picture>
                <source media='(max-width: 600px)' srcSet={slide.alt} />
                <img className='slide-image' src={slide.urls} alt=''/>
              </picture>
                
                <h3 className='slide-text'>{slide.description}</h3>
            </div>
        ))}
    </section>
  );
}

export default SliderContent;