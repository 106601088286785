import { useState, useEffect } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';
import './emprs.css';

import LOGO1 from '../../assets/logos/logo_1.png';
import LOGO2 from '../../assets/logos/logo_2.png';
import LOGO3 from '../../assets/logos/logo_3_.png';
import LOGO4 from '../../assets/logos/logo_4.png';

const Emprs = () => {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return ( 
    
    <section data-aos='fade-up' className='container-width-sm slidxer-container'>

      <div className='slidexr'>

          <div className='slide-track'>

              <div className='slidex'>

              <a href='https://casadasartes.ao/' target='blank'><img src={LOGO1} alt='logo1' /></a>
              </div>
              <div className='slidex'>
              <a href='https://infrasat.co.ao/cgi-sys/suspendedpage.cgi' target='blank'><img src={LOGO2} alt='logo1' /></a>
              </div>
              <div className='slidex'>
              <a href='https://www.cmc.ao/sites/main/pt/Paginas/Home.aspx' target='blank'><img src={LOGO3} alt='logo1' /></a>
              </div>
              <div className='slidex'>
              <a href='https://www.angolalng.com/' target='blank'><img src={LOGO4} alt='logo1' /></a>
              </div>
              <div className='slidex'>

              <a href='https://casadasartes.ao/' target='blank'><img src={LOGO1} alt='logo1' /></a>
              </div>
              <div className='slidex'>
              <a href='https://infrasat.co.ao/cgi-sys/suspendedpage.cgi' target='blank'><img src={LOGO2} alt='logo1' /></a>
              </div>
              <div className='slidex'>
              <a href='https://www.cmc.ao/sites/main/pt/Paginas/Home.aspx' target='blank'><img src={LOGO3} alt='logo1' /></a>
              </div>
              <div className='slidex'>
              <a href='https://www.angolalng.com/' target='blank'><img src={LOGO4} alt='logo1' /></a>
              </div>
              <div className='slidex'>

              <a href='https://casadasartes.ao/' target='blank'><img src={LOGO1} alt='logo1' /></a>
              </div>
              <div className='slidex'>
              <a href='https://infrasat.co.ao/cgi-sys/suspendedpage.cgi' target='blank'><img src={LOGO2} alt='logo1' /></a>
              </div>
              <div className='slidex'>
              <a href='https://www.cmc.ao/sites/main/pt/Paginas/Home.aspx' target='blank'><img src={LOGO3} alt='logo1' /></a>
              </div>
              <div className='slidex'>
              <a href='https://www.angolalng.com/' target='_blank'><img src={LOGO4} alt='logo1' /></a>
              </div>
              <div className='slidex'>

              <a href='https://casadasartes.ao/' target='_blank'><img src={LOGO1} alt='logo1' /></a>
              </div>
              <div className='slidex'>
              <a href='https://infrasat.co.ao/cgi-sys/suspendedpage.cgi' target='blank'><img src={LOGO2} alt='logo1' /></a>
              </div>
              <div className='slidex'>
              <a href='https://www.cmc.ao/sites/main/pt/Paginas/Home.aspx' target='blank'><img src={LOGO3} alt='logo1' /></a>
              </div>
              <div className='slidex'>
              <a href='https://www.angolalng.com/' target='blank'><img src={LOGO4} alt='logo1' /></a>
              </div>

          </div>

      </div>

    </section>
  )
}

export default Emprs;