import { useState, useEffect } from 'react'

import Aos from 'aos';
import 'aos/dist/aos.css';

import { LinkData } from './LinkData';
import './linksyt.css';

function Linksyt () {
    
    useEffect(() => {
        Aos.init({ duration: 3000 });
      }, []);

    const [file, setFile] = useState(null);
  return (
    <section className='linkyt'>
    <div className='lk-container'>
        <div data-aos='fade-up' data-aos-duration='3000' className='linkyt-container'>
            {
                LinkData.map((file, index) => (
                    <div className='link-media' key={index} onClick={() => setFile(file)}>
                        {
                            <div>
                                <h6>{file.description}</h6>
                                <a href={file.link} target='blank'><img data-aos='flip-right' data-aos-duration='3000' src={file.url} alt='' /></a>
                            </div>
                    
                        }
                    </div>
                ))
            }
        </div> 
    </div>
    </section>
  )
}

export default Linksyt