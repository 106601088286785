import React from 'react';

import Slider from '../components/Slider'
import SemServices from './../components/semi-services/SemServices';
import Emprs from './../components/emprs/Emprs';
import Linksyt from '../components/linksyt/Linksyt';

function Home () {
  return (
    <>
      <Slider/>
      <SemServices/>
      <Linksyt/>
      <Emprs/>
    </>
  )
}

export default Home;