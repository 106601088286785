export const LinkData = [
    {
        type: 'image', 
        description: 'Del NDozy 2020',
        url: require('./img4.png'), 
        link: 'https://www.youtube.com/watch?v=OTK3UOanXVA',
    },
    {
        type: 'image', 
        url: require('./img4.png'), 
        link: 'https://www.youtube.com/watch?v=OTK3UOanXVA',
    },
    {
        type: 'image', 
        url: require('./img4.png'), 
        link: 'https://www.youtube.com/watch?v=OTK3UOanXVA',
    },
    {
        type: 'image', 
        url: require('./img5.png'), 
        link: 'https://www.youtube.com/watch?v=OTK3UOanXVA',
    },
    {
        type: 'image', 
        url: require('./img6.png'), 
        link: 'https://www.youtube.com/watch?v=OTK3UOanXVA',
    },
    {
        type: 'image', 
        url: require('./img7.png'), 
        link: 'https://www.youtube.com/watch?v=OTK3UOanXVA',
    },
    {
        type: 'image', 
        url: require('./img4.png'), 
        link: 'https://www.youtube.com/watch?v=OTK3UOanXVA',
    },
]