import { useEffect, useState } from 'react'
import { GalleryData } from './GalleryData';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './gallery.css';  

function Gallery () {

    const [ loading, setLoading ] = useState(false)
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])

    useEffect(() => {
        Aos.init({ duration: 3000 });
      }, []);
    
    const [data,setData] = useState([]);
    const [collection,setCollection] = useState([]);

    useEffect(() => {
        setData(GalleryData);
        setCollection([...new Set(GalleryData.map((item)=> item.title))])
    },[])

    const gallery_filter = (itemData) => {
        const filterData = GalleryData.filter((item)=> item.title === itemData);
        setData(filterData);
    }

        const [item, setItem] = useState(null);

  return (
    <div className='gallery'>
            <div className='filterItem'>
                <ul>
                    <li><button onClick={()=> setData(GalleryData)}>Todos</button></li>
                    {
                        collection.map((item)=> <li><button onClick={()=>{gallery_filter(item)}}>{item}</button></li>)
                    }
                </ul>
            </div>
        <div className='lk-container'>
                    
            <div className='linkyt-container'>
                
                {
                    data.map((item) => <div key={item.id} className='link-media' onClick={() => setItem(item)} ><img src={item.image}/></div>)
                }
            </div>

                <div className='popup-media' style={{display: item ? 'block' : 'none'}}>
            <span onClick={() => setItem(null)}>&times;</span>
            {
                item?.title === 'video'
                ? <video src={item.image} muted autoPlay controls />
                : <img src={item?.image} />
            }
            </div>
        </div>
    </div>
  )
}

export default Gallery;