export const GalleryData = [
    {
        id: 1,
        title: 'Banner',
        image: './image/thumb1.jpg', 
    },
    {
        id: 2,
        title: 'Banner',
        image: './image/thumb2.jpg', 
    },
    {
        id: 3,
        title: 'Banner',
        image: './image/thumb3.jpg', 
    },
    {
        id: 4,
        title: 'Banner',
        image: './image/thumb4.jpg', 
    },
    {
        id: 5,
        title: 'Banner',
        image: './image/thumb5.jpg', 
    },
    {
        id: 6,
        title: 'Banner',
        image: './image/thumb6.jpg', 
    },
    {
        id: 7,
        title: 'Banner',
        image: './image/thumb7.jpg', 
    },
    {
        id: 8,
        title: 'Banner',
        image: './image/thumb8.jpg', 
    },
    {
        id: 9,
        title: 'Banner',
        image: './image/thumb9.jpg', 
    },
    {
        id: 10,
        title: 'Banner',
        image: './image/thumb10.jpg', 
    },
    {
        id: 11,
        title: 'Banner',
        image: './image/thumb11.jpg', 
    },
    {
        id: 12,
        title: 'Banner',
        image: './image/thumb12.jpg', 
    },
    {
        id: 13,
        title: 'Banner',
        image: './image/thumb13.jpg', 
    },
    {
        id: 14,
        title: 'Logo',
        image: './image/thumb14.jpg', 
    },
    {
        id: 15,
        title: 'Logo',
        image: './image/thumb15.jpg', 
    },
    {
        id: 16,
        title: 'Banner',
        image: './image/thumb16.jpg', 
    },
    {
        id: 17,
        title: 'Logo',
        image: './image/thumb17.jpg', 
    },
    {
        id: 18,
        title: 'Logo',
        image: './image/thumb18.jpg', 
    },
    {
        id: 19,
        title: 'Logo',
        image: './image/thumb19.jpg', 
    },
    {
        id: 20,
        title: 'Logo',
        image: './image/thumb20.jpg', 
    },
    {
        id: 21,
        title: 'Logo',
        image: './image/thumb21.jpg', 
    },
    {
        id: 22,
        title: 'Banner',
        image: './image/thumb22.jpg', 
    },
    {
        id: 23,
        title: 'Banner',
        image: './image/thumb23.jpg', 
    },
    {
        id: 27,
        title: 'Banner',
        image: './image/thumb27.jpg', 
    },
    {
        id: 28,
        title: 'Flayer',
        image: './image/thumb28.jpg', 
    },
    {
        id: 29,
        title: 'Flayer',
        image: './image/thumb29.jpg', 
    },
    {
        id: 30,
        title: 'Flayer',
        image: './image/thumb30.jpg', 
    },

]
