import React from 'react'
import RECIM from '../assets/imagens/slide5-alt.jpeg'
import './recrutamento.css'


const Recrutamento = () => {
  return (

    <section className='recrutamento'>
        <>
            <div data-aos='fade-up' className='container-width recrutamento__back-image'>
                <h2 data-aos='fade-right'>Recrutamento</h2>
            </div>   
            <div className='container recrutamento-container'>
                <img src={RECIM}/>
            </div>
        </>

    </section>
  )
}

export default Recrutamento