import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './semservices.css';

import BRANDING from '../../assets/icons/branding.png';
import CAMPANHA from '../../assets/icons/campanha.png';
import SOCIALS from '../../assets/icons/redes-sociais.png';
import WEBSITES from '../../assets/icons/website.png';

function SemServices () {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <section className='container-width semservices'>

      <div className='container semservices-container'>

        <div data-aos='fade-up' data-aos-duration='1000' className='semservices-details details-other'>
          <h4>NOSSOS SERVIÇOS</h4>
          <p className='p-inte'>Prestamos serviços com qualidade, eficiência, responsabilidade e integridade.</p>
          <Link to='/About' className='btn btn-secundary'>SAIBA MAIS</Link>
        </div>

        <div className='semservices-right'>

          <div data-aos='fade-up' data-aos-duration='2000'  className='semservices-details'>
            <div className='lgg'>
              <div><img className='semservices-logos' src={BRANDING} alt='logo-redes-sociais'/></div>
              <div><h4>BRANDING</h4></div>
              
            </div>
            <p>Uma série de actividades que se destinam exclusivamente a gestão de uma marca, desde a sua concepção até ao seu público alvo.</p>
          </div>
          <div data-aos='fade-up' data-aos-duration='3000' className='semservices-details'>
            <div className='lgg'>
              <div><img className='semservices-logos' src={CAMPANHA} alt='logo-redes-sociais'/></div>
              <h4>CAMPANHA</h4>
            </div>
            <p>Técnicas e métodos aplicados aos estudos das necessidades dos mercados e seus principais componentes, como públicos, vendas e produtos para o desenvolvimento das empresas.</p>
          </div>
          <div data-aos='fade-up' data-aos-duration='4000' className='semservices-details'>
            <div className='lgg'>
              <div><img className='semservices-logos' src={SOCIALS} alt='logo-redes-sociais'/></div>
              <h4>REDES SOCIAIS</h4>
            </div>
            <p>acções que visa otimizar o uso das redes sociais no relacionamento e interação com o usuário que também é o público consumidor. Estatégia indispensável.</p>
          </div>
          <div data-aos='fade-up' data-aos-duration='5000' className='semservices-details'>
            <div className='lgg'>
              <div><img className='semservices-logos' src={WEBSITES} alt='logo-redes-sociais'/></div>
              <h4>WEB SITES</h4>
            </div>
            <p>conjunto de estratégias farão com que empresas seja encontrada facilmente pelos seus cliente, seja por meio do (Google Ads) ou orgânico (SEO).</p>
          </div>

        </div>
        
      </div>
    </section>
  )
}

export default SemServices;