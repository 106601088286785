import { useEffect, useState } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link, NavLink } from 'react-router-dom';
import './navbar.css';

import {BsFacebook} from 'react-icons/bs';
import {RiInstagramFill} from 'react-icons/ri';
import {BsYoutube} from 'react-icons/bs';
import {MdOutlineClearAll, MdOutlineClose} from 'react-icons/md';

import LOGONDOZY from '../../assets/logos/logo1.png';

const Navbar = () => {

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  const [isNavShowing, setIsNavShowing] = useState(false);

  return (
      <div className='navbar'>
          <div data-aos='fade-up' className='container-width-sm h-container'>

          <Link data-aos='fade-left' className='logo1' to='/' onClick={() => setIsNavShowing(false)}>
            <img src={LOGONDOZY} alt='logo-armed'/>
          </Link>

              <ul className={`h-menu ${isNavShowing ? 'show__nav' : 'hide__nav'}`}>
                <li>
                <NavLink id='a' onClick={() => setIsNavShowing(prev => !prev )} className={({isActive}) => isActive ? 'active-nav' : ''} to='/'>INÍCIO</NavLink>
                </li>
                <li>
                <NavLink id='a' onClick={() => setIsNavShowing(prev => !prev )} className={({isActive}) => isActive ? 'active-nav' : ''} to='/About'>SOBRE</NavLink>
                </li>
                <li>
                <NavLink id='a' onClick={() => setIsNavShowing(prev => !prev )} className={({isActive}) => isActive ? 'active-nav' : ''} to='/Store'>STORE</NavLink>
                </li>
                <li>
                <NavLink id='a' onClick={() => setIsNavShowing(prev => !prev )}  className={({isActive}) => isActive ? 'active-nav' : ''} to='/Services'>SERVIÇOS</NavLink>
                </li>
                <li>
                <NavLink id='a' onClick={() =>  setIsNavShowing(prev => !prev ) } className={({isActive}) => isActive ? 'active-nav' : ''} to='/Contact'>CONTACTOS</NavLink>
                </li>
              </ul>
              
                  <div data-aos='fade-left' className='h-socials'>
                      <a href='https://www.facebook.com/ndozycomunicacoes' target='blank'><BsFacebook/></a>
                      <a href='https://www.instagram.com/ndozycomunicacoes/#' target='blank'><RiInstagramFill/></a>
                      <a href='https://www.youtube.com/@ndozycomunicacoes2944' target='blank'><BsYoutube/></a>
                  </div>
              <button className='nav__toggle-btn' onClick={() => setIsNavShowing(prev => !prev )}>
                {
                  isNavShowing ? <MdOutlineClose/> : <MdOutlineClearAll/>
                }
              </button>
          </div>
      </div>
  )
}

export default Navbar;