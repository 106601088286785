import { useEffect } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';

import Gallery from '../components/gallery/Gallery';
import './store.css'

const Store = () => {

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <>
    <div data-aos='fade-up' className='container-width store__back-image'>
      <h2 data-aos='fade-right'>Stories</h2>
    </div>
      <Gallery/>
    </>
  )
}

export default Store;