import First from '../../assets/imagens/slide5.jpeg'
import Fourth from '../../assets/imagens/slide5-alt.jpeg'

export default [
    {
        title: 'Recrutamento',
        description: '',
        alt: Fourth,
        urls: First,
    },
];